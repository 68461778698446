import { markExerciseComplete } from "api/exerciseApi";
import Actions from "components/actions"
import Svg from "components/svg";
import { has } from "immer/dist/internal";
import { split } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { HiChevronDoubleUp, HiChevronDoubleDown } from 'react-icons/hi';
import ReactPlayer from "react-player";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { messageErrors, isVideoType, toHHMMSS, isSafari } from 'utils/utils';
import './exerciseItem.scss'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ExerciseItem = (props: any) => {
  const { exercise, index, selectedDate, isTherapistSite, isLoading, setIsLoading, expand, expandAll, isExpandAll,handleExpandExercise } = props
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState<any>({})
  const [isVideo, setIsVideo] = useState(false)
  const [hasVideoUrl, setHasVideoUrl] = useState(false)
  const [fileUrl, setFileUrl] = useState(exercise?.file?.url);
  const [isPlaying, setIsPlaying] = useState(false)

  const mutation = useMutation('markExerciseComplete', { mutationFn: markExerciseComplete });

  useEffect(() => {
    setOpen({})
    const videoUrl = exercise.videoUrl
    const fileId = exercise?.file?.id

    if (!!videoUrl) {
      setHasVideoUrl(true)
      setFileUrl(videoUrl)
      setIsVideo(true)
    } else if (!!fileId) {
      if (isVideoType(exercise?.file?.type)) {
        setIsVideo(true)
      } else setIsVideo(false)
      setHasVideoUrl(false)
      setFileUrl(exercise?.file?.url)
    }
    else {
      setFileUrl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise.videoUrl, exercise])

  const handleMarkComplete = (e: any, exercise: any) => {
    e.stopPropagation();
    if (setIsLoading) {
      setIsLoading(true);
    }
    const payload = {
      exerciseId: exercise.id,
      completedDate: moment(selectedDate).format('YYYY-MM-DD')
    }

    mutation.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries('getExerciseChart')
        queryClient.invalidateQueries('getExerciseToday')
        queryClient.invalidateQueries('getExerciseOfPatient')
        const message: string = t('exerciseCompletion');
        toast.success(message);
        if (setIsLoading) {
          setIsLoading(false);
        }
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
        if (setIsLoading) {
          setIsLoading(false);
        }
      },
    });

  }
  const handleClickDetail = (e: any) => {
    e.stopPropagation();

  }
  const percentCompleted = Math.round(exercise.timesPerDay ? exercise?.exerciseCompletion?.length / exercise.timesPerDay * 100 : 0)

  const isShowSets = !isNaN(parseInt(exercise.sets)) && parseInt(exercise.sets) > 0
  const isShowReps = !isNaN(parseInt(exercise.reps)) && parseInt(exercise.reps) > 0
  const isShowDuration = !isNaN(parseInt(exercise.duration)) && parseInt(exercise.duration) > 0

  return (
    <div className="exercise-item-container">
      <div className={`exercise-item border bg-white ${exercise.timesPerDay > 1 ? 'rounded-t-lg rounded-l-lg' : 'rounded-lg'}`} onClick={() => handleExpandExercise(index)}>
        <div className="py-2 pl-4 pr-2 flex justify-between items-center">
          <div>
            <div className="flex">
              <Actions>
                <Actions.Item
                  show
                  action={() => handleExpandExercise(index)}
                  icon={!((expand && !isExpandAll) || (expandAll && isExpandAll)) ? <HiChevronDoubleDown className="w-5 h-5 text-on-primary-container" strokeWidth={0.5} /> : <HiChevronDoubleUp className="w-5 h-5 text-on-primary-container" strokeWidth={0.5} />}
                />
              </Actions>
              <div className="flex flex-col">
                <div className="font-semibold text-secondary text-sm leading-3.5">
                  {exercise.name}
                </div>
                <div className="flex">
                  {isShowSets && <div className={`${(isShowReps || isShowDuration) ? 'border-gray-500 border-r' : ''} flex items-center text-sm text-gray-500 font-small font-normal height-small pr-2 mr-2 h-5`}>
                    {exercise.sets} {t('patientProgramExercises.sets')}
                  </div>}
                  {isShowReps && <div className={`${isShowDuration ? 'border-gray-500 border-r' : ''} flex items-center text-sm text-gray-500 font-small font-normal height-small  pr-2 mr-2 h-5`}>
                    {exercise.reps} {t('patientProgramExercises.reps')}
                  </div>}
                  {isShowDuration && <div className="flex items-center text-sm text-gray-500 font-small font-normal height-small pr-2 mr-2 h-5">
                    {t('patientProgramExercises.duration')}: {toHHMMSS(exercise.duration)}
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Actions>
              <Actions.Item
                cursor={!isTherapistSite && percentCompleted !== 100}
                show
                action={(e: any) => percentCompleted !== 100 && !isTherapistSite ? handleMarkComplete(e, exercise) : null}
                icon={
                  percentCompleted >= 100 ? <Svg name='completed-icon' strokeWidth={0.5} /> :
                    <CircularProgressbarWithChildren value={percentCompleted} className="circle-progress" strokeWidth={14}
                    >
                      {percentCompleted === 0 ? <Svg name='check-outline-icon' className="w-7 h-7" strokeWidth={0.5} /> : <Svg name='check-icon' className="w-7 h-7" strokeWidth={0.5} />}
                    </CircularProgressbarWithChildren>}
              />
            </Actions>
          </div>
        </div>
        {((expand && !isExpandAll) || (expandAll && isExpandAll)) && (

          <div className="px-4" onClick={(e: any) => handleClickDetail(e)}>
            {fileUrl !== '' && fileUrl !== null && (
              isVideo ? (

                <div className='w-full relative aspect-video'>
                  {!isSafari() && !hasVideoUrl && !isPlaying &&
                    <span className="absolute left-0 right-0 top-0 bottom-10 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                      <Svg name='play-icon' className="w-[60px] h-[60px]" />
                    </span>}
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    controls
                    url={fileUrl}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    playIcon={hasVideoUrl ? <button className='hidden'>{''}</button> : <Svg className="w-[60px] h-[60px]" name='play-icon' />}
                  />
                  <div className="flex items-center justify-center">
                    <a href={exercise?.videoUrl} rel="noreferrer" className="pt-4 text-tertiary text-center underline" target="_blank">{exercise?.videoUrl}</a>
                  </div>
                </div>

              ) : (
                <div className='w-full aspect-video'>
                  <img className="preview w-full h-full object-cover rounded" src={fileUrl} alt="avatar" />
                </div>
              ))}
            <div className="py-4 whitespace-pre-line">
              {exercise.description}
            </div>
          </div>
        )
        }
      </div>
      {
        exercise.timesPerDay > 1 && (
          <div className="flex justify-end">
            <div className="session-completed bg-tertiary font-semibold text-sm text-white">
              {exercise?.exerciseCompletion?.length}/{exercise.timesPerDay ?? 0} <span className="font-light text-sm">{t('sessionscompleted')}</span>
            </div>
          </div>
        )
      }

    </div>
  )
}
export default ExerciseItem