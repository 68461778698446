/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { getSecondsFromHHMMSS, handleTrimString, isVideoType, messageErrors, toHHMMSS } from 'utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './editExerciseModal.scss'
import { optionType, roleType } from 'utils/proptypes';
// import { getUsersByEmail, createOrgUser } from 'api/userApi';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { filter, flattenDeep, isEmpty, omit, omitBy } from 'lodash';
import { IMAGE_TYPE_REGEX, roleName } from 'utils/constants';
import InputText from 'components/inputText';
import CheckboxCustom from './CheckboxCustom';
import FileUpload from './fileUpload';
import { updateExercise, uploadFile } from 'api/exerciseApi';
import moment, { duration } from 'moment';
import { getSeconds } from 'date-fns';
import SpinnerComponent from 'components/spinner';
import { getExerciseTemplates } from 'api/exerciseTemplateApi';
import SelectExerciseTemplateModal from 'components/modal/selectExerciseTemplateModal';
import DiscardSaveModal from 'components/modal/discardSaveModal';
import { updateProgramTemplateExercise } from 'api/programTemplateApi';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSelector } from '../../../redux/selectors';
import AlertUploadingModal from '../../../components/modal/alertUploadingModal';
import { uploadAction } from '../../../redux/actions';

const EditExerciseModal = (props: any) => {
    const { openModal, setOpenModal, selectedExercise, setSelectedExercise, queryClient, program, mutationCreateProgram, mutationCreateProgramTemplate, isTemplate } = props;
    const { fetchCurrentUser } = useUser();
    const dispatch = useDispatch();
    const {uploading} = useSelector(uploadSelector)
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [hasVideoUrl, setHasVideoUrl] = useState(false);
    const [fileUrl, setFileUrl] = useState(selectedExercise?.file?.url || '');
    const [isVideo, setIsVideo] = useState(false);
    const [fileErrors, setFileErrors] = useState(false)
    let fileReader: any = new FileReader();
    const [file, setFile]: any = useState();
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [disableVideoUrl, setDisableVideoUrl] = useState(false);
    const [videoImage, setVideoImage] = useState(null);
    const [selectedExerciseTemplate, setSelectedExerciseTemplate] = useState<any>();
    const [exerciseTemplates, setExerciseTemplates] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [openSelectTemplateModal, setOpenSelectTemplateModal] = useState(false)
    const [openDiscardSaveModal, setOpenDiscardSaveModal] = useState(false)
    const [openAlertUploadingModal, setOpenAlertUploadingModal] = useState(false)

    const ref: any = useRef(null);

    const initExercise = {
        name: '',
        videoUrl: '',
        fileId: null,
        description: '',
        sets: null,
        reps: null,
        duration: '',
        timesPerDay: 1,
        order: null,
        isSunday: false,
        isMonday: false,
        isTuesday: false,
        isWednesday: false,
        isThursday: false,
        isFriday: false,
        isSaturday: false,
        isArchived: false,
    }
    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')),
    });

    const hookForm: any = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const { data: exerciseTemplatesData, isFetching, refetch } = useQuery(
        ['getExerciseTemplate', searchQuery],
        () =>
            getExerciseTemplates({
                page: 0,
                limit: 0,
                searchQuery: searchQuery,
            }),
        {
            enabled: searchQuery !== '',
            // staleTime: Infinity,
        },
    );
    const handleMutationExercise = (payload: any) => {
        return !isTemplate ? updateExercise(selectedExercise?.id, payload) : updateProgramTemplateExercise(selectedExercise?.id, payload)
    }
    const mutationExercise = useMutation('updateExercise', {
        mutationFn: handleMutationExercise,
        onSuccess: () => {
            const message: string = selectedExercise ? t('Update Exercise successfully') : t('Create Exercise successfully');
            toast.success(message);
            hookForm.reset()
            setOpenModal(false)
            setSelectedExercise(null)
            if (!isTemplate) {
                queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getProgramById', { refetchActive: true }, { cancelRefetch: true });
            } else {
                queryClient.invalidateQueries('getProgramTemplates', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getProgramTemplateById', { refetchActive: true }, { cancelRefetch: true });
            }
        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const {
        formState: { errors, isDirty },
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        setError,
        resetField
    } = hookForm
    const toggleHandler = () => {
        if (isDirty || uploading) {
            setOpenDiscardSaveModal(true)
        } else {
            dispatch(uploadAction.setUploading(false));
            handleClose()
        }
    }

    const handleClose = () => {
        setOpenModal(!openModal)
        reset()
        setSelectedExercise(null)
    }


    const handleSave = async () => {
        const values = hookForm.getValues();
        try {
            let exercisePayload: any
            if (values?.file?.name) {
                setIsUploadingFile(true);
                const currentFileName = values.file.name
                  .split('.')
                  .map((item: string) => item.toLowerCase())
                  .join('.');
                const formData = new FormData();
                formData.append('file', values.file, currentFileName);
                const { data } = await uploadFile({ file: formData });
                setIsUploadingFile(false);
                if (data) {
                    exercisePayload = { ...omit(values, ['file', 'fileUrl']), fileId: data.id, duration: values.duration ? Math.max(0, getSecondsFromHHMMSS(values.duration)) : null }
                }
            } else if (values?.file !== null) {
                exercisePayload = { ...omit(values, 'file'), duration: values.duration ? Math.max(0, getSecondsFromHHMMSS(values.duration)) : null }

            } else {
                exercisePayload = { ...omit(values, 'file'), fileId: null, duration: values.duration ? Math.max(0, getSecondsFromHHMMSS(values.duration)) : null }

            }
            if (selectedExercise) {
                mutationExercise.mutate(exercisePayload)
            } else {
                const convertExercises = [exercisePayload, ...program?.data?.exercises].map((item, index) => ({ ...item, order: index }))
                const programPayload = { ...program?.data, exercises: convertExercises }
                !isTemplate ? mutationCreateProgram.mutate(programPayload) : mutationCreateProgramTemplate.mutate(programPayload)
            }
        } catch (error) {
            console.log("error", error);
            setIsUploadingFile(false);
        }
    }

    const onSubmit = async () => {
        if (uploading) {
            setOpenAlertUploadingModal(true);
        } else {
            setOpenDiscardSaveModal(false);
            await handleSave();
        }
    }

    const handleCheckAll = () => {
        if (!isCheckAll) {
            hookForm.setValue(`isSunday`, true, { shouldDirty: true });
            hookForm.setValue(`isMonday`, true, { shouldDirty: true });
            hookForm.setValue(`isTuesday`, true, { shouldDirty: true });
            hookForm.setValue(`isWednesday`, true, { shouldDirty: true });
            hookForm.setValue(`isThursday`, true, { shouldDirty: true });
            hookForm.setValue(`isFriday`, true, { shouldDirty: true });
            hookForm.setValue(`isSaturday`, true, { shouldDirty: true });
            setIsCheckAll(true);
        } else {
            hookForm.setValue(`isSunday`, false, { shouldDirty: true });
            hookForm.setValue(`isMonday`, false, { shouldDirty: true });
            hookForm.setValue(`isTuesday`, false, { shouldDirty: true });
            hookForm.setValue(`isWednesday`, false, { shouldDirty: true });
            hookForm.setValue(`isThursday`, false, { shouldDirty: true });
            hookForm.setValue(`isFriday`, false, { shouldDirty: true });
            hookForm.setValue(`isSaturday`, false, { shouldDirty: true });
            setIsCheckAll(false);
        }
    };

    let watchSunday = hookForm.watch(`isSunday`);
    let watchMonday = hookForm.watch(`isMonday`);
    let watchTuesday = hookForm.watch(`isTuesday`);
    let watchWednesday = hookForm.watch(`isWednesday`);
    let watchThursday = hookForm.watch(`isThursday`);
    let watchFriday = hookForm.watch(`isFriday`);
    let watchSaturday = hookForm.watch(`isSaturday`);

    let isSunday = hookForm.getValues(`isSunday`);
    let isMonday = hookForm.getValues(`isMonday`);
    let isTuesday = hookForm.getValues(`isTuesday`);
    let isWednesday = hookForm.getValues(`isWednesday`);
    let isThursday = hookForm.getValues(`isThursday`);
    let isFriday = hookForm.getValues(`isFriday`);
    let isSaturday = hookForm.getValues(`isSaturday`);

    useEffect(() => {
        if (!isMonday || !isTuesday || !isWednesday || !isThursday || !isFriday || !isSaturday || !isSunday) {
            setIsCheckAll(false);
        }
        if (isMonday && isTuesday && isWednesday && isThursday && isFriday && isSaturday && isSunday) {
            setIsCheckAll(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchSunday, watchMonday, watchTuesday, watchWednesday, watchThursday, watchFriday, watchSaturday]);
    const handleOnBlur = () => {
        setFileUrl(hookForm.getValues(`videoUrl`));
        setIsVideo(true);
        setHasVideoUrl(true);
    };


    const handleUploadFile = (file: any) => {
        hookForm.setValue('file', file, { shouldDirty: true });
        const currentFileName = file.name
            ?.split('.')
            .map((item: string) => item.toLowerCase())
            .join('.');
        if (file.size > 500000000) {
            setFileErrors(true);
            setError(`file`, {
                type: 'required',
                message: t('errorSize500MB'),
            });
            setFileUrl(URL.createObjectURL(file));
            hookForm.setValue(`fileUrl`, URL.createObjectURL(file));
            file.isUploaded = true;
            setFile(file);
            setDisableVideoUrl(true);
        } else {
            setFileErrors(false);
            clearErrors(`file`);
            setFileUrl(URL.createObjectURL(file));
            hookForm.setValue(`fileUrl`, URL.createObjectURL(file));
            hookForm.setValue(`file`, file, { shouldDirty: true });
            file.isUploaded = true;
            setFile(file);
            //   update(`file`, file);
            setDisableVideoUrl(true);
        }

        if (IMAGE_TYPE_REGEX.test(currentFileName)) {
            setIsVideo(false);
        } else {
            setIsVideo(true);
        }
    };

    const onBlurTimeInput = (event: any) => {
        const value = event.target.value;
        const seconds = Math.max(0, getSecondsFromHHMMSS(value));
        const time = toHHMMSS(seconds);
        if (time !== '00:00') {
            hookForm.setValue(`duration`, time);
        } else {
            resetField(`duration`);
        }
    };



    const handleRemoveImage = (e: any) => {
        setFileErrors(false);
        setFile(null);
        setFileUrl('');
        setDisableVideoUrl(false);
        hookForm.setValue(`file`, null, { shouldDirty: true });
        hookForm.setValue(`fileUrl`, null);
        // update(`file`, null);
        e.stopPropagation();
    };

    const handleClickLibraryIcon = async () => {
        setOpenSelectTemplateModal(true)
    }

    useEffect(() => {
        const videoUrl = hookForm.getValues(`videoUrl`);
        const fileId = hookForm.getValues(`fileId`);

        if (!!videoUrl) {
            setHasVideoUrl(true);
            setFileUrl(videoUrl);
            setIsVideo(true);
        } else if (!!fileId) {
            if (isVideoType(selectedExercise?.file?.type)) {
                setIsVideo(true)
            } else setIsVideo(false)
            setDisableVideoUrl(true);
            setHasVideoUrl(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hookForm.getValues(`videoUrl`)]);

    useEffect(() => {
        if (exerciseTemplatesData) {
            setExerciseTemplates(exerciseTemplatesData?.data?.entities);
        }
    }, [exerciseTemplatesData, setSearchQuery]);

    useEffect(() => {
        if (selectedExercise) {
            reset(selectedExercise)
        } else reset(initExercise)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedExercise])

    useEffect(() => {
        if (selectedExerciseTemplate) {
            const initData = { ...omit(selectedExerciseTemplate, 'id'), timesPerDay: 1, isTemplate: true }
            reset(initData)
            if (selectedExerciseTemplate?.file?.url) {
                if (isVideoType(selectedExerciseTemplate?.file?.type)) {
                    setIsVideo(true);
                } else setIsVideo(false);
                setDisableVideoUrl(true);
                setFileUrl(hookForm.getValues(`file.url`));
            } else if (selectedExerciseTemplate.videoUrl) {
                setHasVideoUrl(true);
                setFileUrl(selectedExerciseTemplate.videoUrl);
                setIsVideo(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedExerciseTemplate]);

    return (
        <div ref={rootRef} className="relative">
            <Modal
                className='modal-edit-exercise'
                show={true}
                size="5xl"
                popup={true}
                root={rootRef.current ?? undefined}
                // onClose={toggleHandler}
                dismissible={true}>
                <CustomModalHeader title={selectedExercise ? t('Edit Exercise') : t('Add Exercise')} toggle={toggleHandler} />
                <Modal.Body className='modal-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-5">
                            <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                <InputText hookForm={hookForm} placeholder={t('Exercise')} name="name" isHolderLabel={true} handleClick={() => handleClickLibraryIcon()}
                                    rightIcon={<HiOutlineBookOpen color='#9CA3AF' width={22} height={18} strokeWidth={2} />}
                                />
                            </div>
                            <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                <InputText hookForm={hookForm} placeholder={t('Description')} name="description" type="textarea" isHolderLabel={true} rows={5} />
                            </div>
                            <div className='flex pt-4'>
                                <div className='w-1/2 bg-gray-50 px-4'>
                                    <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                        <InputText hookForm={hookForm} placeholder={t('Sets')} name="sets" type="number" isHolderLabel={true} />
                                    </div>

                                    <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                        <InputText hookForm={hookForm} placeholder={t('Reps')} name="reps" type="number" isHolderLabel={true} />
                                    </div>
                                    <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                        <InputText hookForm={hookForm} placeholder={t('Duration')} name="duration" isHolderLabel={true} handleOnBlur={onBlurTimeInput} />
                                    </div>
                                    <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                        <InputText hookForm={hookForm} placeholder={t('Times per day')} name="timesPerDay" type="number" isHolderLabel={true} />
                                    </div>
                                    <div className={`${errors?.lastName && 'border-error'} pt-4`}>
                                        <div className="days-container">
                                            <div className="flex justify-between days">
                                                <div className={`check-all-button ${isCheckAll ? 'checked' : 'unchecked'}`} onClick={handleCheckAll}>
                                                    ALL
                                                </div>
                                                <CheckboxCustom hookForm={hookForm} name="isSunday" value={selectedExercise?.isSunday} day="SU" />
                                                <CheckboxCustom hookForm={hookForm} name="isMonday" value={selectedExercise?.isMonday} day="MO" />
                                                <CheckboxCustom hookForm={hookForm} name="isTuesday" value={selectedExercise?.isTuesday} day="TU" />
                                                <CheckboxCustom
                                                    hookForm={hookForm}
                                                    name="isWednesday"
                                                    value={selectedExercise?.isWednesday}
                                                    day="WE"
                                                />
                                                <CheckboxCustom hookForm={hookForm} name="isThursday" value={selectedExercise?.isThursday} day="TH" />
                                                <CheckboxCustom hookForm={hookForm} name="isFriday" value={selectedExercise?.isFriday} day="FR" />
                                                <CheckboxCustom hookForm={hookForm} name="isSaturday" value={selectedExercise?.isSaturday} day="SA" />
                                            </div>
                                        </div>                        </div>
                                </div>

                                <div className='w-1/2 flex flex-col px-2'>
                                    <div className='grow'>
                                        <FileUpload
                                            hasVideoUrl={hasVideoUrl}
                                            // index={index}
                                            hookForm={hookForm}
                                            file={file}
                                            videoImage={videoImage}
                                            isVideo={isVideo}
                                            fileReader={fileReader}
                                            handleUploadFile={handleUploadFile}
                                            fileUrl={fileUrl}
                                            handleRemoveImage={handleRemoveImage}
                                            selectedExercise={selectedExercise}
                                        />
                                        {fileErrors && (
                                            <div className={`w-32 text-red-600 text-xs font-normal mt-1 veri-modal break-words height-50`}>{t('errorSize500MB')}</div>
                                        )}
                                    </div>
                                    <div className="pt-4" onBlur={handleOnBlur}>
                                        <InputText disabled={disableVideoUrl} handleOnBlur={handleOnBlur} hookForm={hookForm} placeholder={t('Video Url')} name="videoUrl" isHolderLabel={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex pt-12 pb-6 h-fit relative">
                                {selectedExercise?.created && (
                                    <div className='text-gray-400 font-normal text-sm'>{moment(selectedExercise?.created).format('MMMM D, YYYY, h:mm A')}</div>
                                )}
                                <div className='absolute mx-auto inset-0 top-[20px] flex items-center justify-center'>
                                    <GroupButton
                                        className="items-center justify-center pb-2"
                                        buttons={[
                                            {
                                                type: 'button',
                                                text: t('modal.cancel'),
                                                classType: 'white',
                                                action: () => toggleHandler(),
                                            },
                                            {
                                                type: 'submit',
                                                text: t('modal.save'),
                                                classType: 'blue',
                                                // isLoading: mutation.isLoading,
                                            },
                                        ]}
                                    />
                                </div>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
            {(mutationExercise.isLoading || mutationCreateProgram.isLoading || isUploadingFile) && <SpinnerComponent />}
            {openSelectTemplateModal && <SelectExerciseTemplateModal setSelectedExerciseTemplate={setSelectedExerciseTemplate} selectedExerciseTemplate={selectedExerciseTemplate} openModal={openSelectTemplateModal} setOpenModal={setOpenSelectTemplateModal} />}
            {openDiscardSaveModal && <DiscardSaveModal openModal={openDiscardSaveModal} setOpenModal={setOpenDiscardSaveModal} onDiscard={handleClose} onSave={handleSubmit(onSubmit)} />}
            {openAlertUploadingModal && <AlertUploadingModal openModal={openAlertUploadingModal} setOpenModal={setOpenAlertUploadingModal} handleContinue={handleSave} />}
        </div>
    );
};
export default EditExerciseModal;
