/* eslint-disable react/jsx-props-no-spreading */
import Actions from 'components/actions';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil, HiOutlineTrash, HiChevronDoubleUp, HiChevronDoubleDown, HiMenuAlt4, HiOutlineArchive, HiOutlineBookOpen } from 'react-icons/hi';
import { isSafari, isVideoType, toHHMMSS } from 'utils/utils';
import Svg from 'components/svg';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import './exerciseRow.scss';
import ConfirmDeleteModal from 'components/modal/confirmDeleteModal';
import { toast } from 'react-toastify';
import { deleteExercise } from 'api/exerciseApi';
import { apiStatus } from 'utils/constants';
import { deleteExerciseTemplate } from 'api/exerciseTemplateApi';
import { deleteProgramTemplateExercise } from 'api/programTemplateApi';

const ExerciseRow = (props: any) => {
  const { isTemplate = false, isPatientSite = false, queryClient, exercise, index, hookForm, expand, expandAll, isExpandAll, onExpandExercise, dragHandleProps, handleArchiveExercise, isArchived = false, setOpenEditModal, setSelectedExercise } = props
  const [t] = useTranslation()
  const isShowSets = !isNaN(parseInt(exercise.sets)) && parseInt(exercise.sets) > 0
  const isShowReps = !isNaN(parseInt(exercise.reps)) && parseInt(exercise.reps) > 0
  // const isShowDuration = !isNaN(parseInt(exercise.duration)) && parseInt(exercise.duration) > 0
  const [fileUrl, setFileUrl] = useState(exercise?.file?.url);
  const [isVideo, setIsVideo] = useState(false)
  const [hasVideoUrl, setHasVideoUrl] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [orientation, setOrientation] = useState("");

  const { getValues } = hookForm
  const exerciseId = isArchived ? exercise.id : getValues(`exercises[${index}].id`);
  const [durationValue, setDurationValue] = useState(exercise?.duration !== '' ? toHHMMSS(exercise?.duration) : '');

  const handleEditExercise = (e: any) => {
    e.stopPropagation();
    setOpenEditModal(true)
    const selectedExercise = { ...exercise, id: isArchived ? exercise.id : getValues(`exercises[${index}].id`) }
    setSelectedExercise(selectedExercise)
  }

  const handleOpenRemoveModal = (e: any) => {
    e.stopPropagation();
    setOpenConfirmDeleteModal(true);

  }

  const handleRemoveExercise = async (exerciseId: string) => {
    try {
      if (!isTemplate) {
        const data = await deleteExercise(exerciseId);
        if (data) {
          const message: string = t('Deleted Exercise successfully');
          toast.success(message);
          queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
          queryClient.invalidateQueries('getProgramById', { refetchActive: true }, { cancelRefetch: true });
        }
      } else {
        const data = await deleteProgramTemplateExercise(exerciseId);
        if (data) {
          const message: string = t('Deleted Exercise successfully');
          toast.success(message);
          queryClient.invalidateQueries('getProgramTemplates', { refetchActive: true }, { cancelRefetch: true });
          queryClient.invalidateQueries('getProgramTemplateById', { refetchActive: true }, { cancelRefetch: true });
        }
      }

    } catch (e) {
      const message: string = t('generalErrors');
      toast.error(message);
    }

  }

  useEffect(() => {
    // setOpen({})
    const videoUrl = exercise.videoUrl
    const fileId = exercise?.file?.id

    if (!!videoUrl) {
      setHasVideoUrl(true)
      setFileUrl(videoUrl)
      setIsVideo(true)
    } else if (!!fileId) {
      if (isVideoType(exercise?.file?.type)) {
        setIsVideo(true)
      } else setIsVideo(false)
      setHasVideoUrl(false)
      setFileUrl(exercise?.file?.url)
    }
    else {
      setFileUrl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise.videoUrl, exercise])

  useEffect(() => {
    if (exercise?.duration !== '') {
      setDurationValue(toHHMMSS(exercise?.duration));
    }
  }, [exercise?.duration]);


 const checkOrientation = (img:any) => {
    if (img.naturalWidth > img.naturalHeight) {
      setOrientation("Horizontal");
    } else if (img.naturalWidth < img.naturalHeight) {
      setOrientation("Vertical");
    } else {
      setOrientation("Square");
    }
  };
  return (
    <>
      <div className='exercise-row flex border w-full p-4 mb-2 shadow-sm rounded-[6px] bg-white' onClick={() => onExpandExercise(index)} {...dragHandleProps}>
        <div className='flex flex-col w-full'>
          <div className='flex justify-between'>
            <div className="flex">
              <Actions>
                <Actions.Item
                  show
                  //   action={() => handleClickExpandExercise(index)}
                  icon={((expand && !isExpandAll) || (expandAll && isExpandAll)) ? <HiChevronDoubleUp className="w-5 h-5 text-on-primary-container" strokeWidth={0.5} /> : <HiChevronDoubleDown className="w-5 h-5 text-on-primary-container" strokeWidth={0.5} />}
                />
              </Actions>
              <div className="flex flex-col">
                <div className="font-semibold text-secondary text-sm leading-3.5">
                  {exercise.name}
                </div>
                <div className="flex items-center">
                  {isShowSets && <div className={`${(isShowReps || durationValue !== '') ? 'border-gray-500 border-r' : ''} flex items-center text-sm text-gray-500 font-small font-normal pr-2 mr-2 h-4`}>
                    {exercise.sets} {t('patientProgramExercises.sets')}
                  </div>}
                  {isShowReps && <div className={`${durationValue !== '' ? 'border-gray-500 border-r' : ''} flex items-center text-sm text-gray-500 font-small font-normal  pr-2 mr-2 h-4`}>
                    {exercise.reps} {t('patientProgramExercises.reps')}
                  </div>}
                  {durationValue !== '' && <div className="flex items-center text-sm text-gray-500 font-small font-normal pr-2 h-4">
                    {t('patientProgramExercises.duration')}: {exercise.duration}
                  </div>}
                  <div className='week-days flex-row flex gap-1'>
                    {exercise.isSunday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>SU </div>}
                    {exercise.isMonday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>MO </div>}
                    {exercise.isTuesday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>TU </div>}
                    {exercise.isWednesday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>WE </div>}
                    {exercise.isThursday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>TH </div>}
                    {exercise.isFriday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>FR </div>}
                    {exercise.isSaturday && <div className='p-1 flex justify-center items-center w-[24px] h-[20px] bg-[#396D7C1A] border rounded-[4px] text-[10px] text-black font-semibold'>SA </div>}
                  </div>

                </div>
              </div>
            </div>
            {!isPatientSite && (
              !isTemplate ? (
                <div className="action-in-large-screen flex justify-end items-center grow ">
                  <Actions>
                    <Actions.Item
                      show
                      action={(e: any) => handleEditExercise(e)}
                      icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                    <Actions.Item
                      show
                      action={(event: any) => handleArchiveExercise(event, exercise, exerciseId, index)}
                      icon={exercise.isArchived ? <Svg name="unarchive-icon" width={20} height={20} /> : <HiOutlineArchive width={20} height={20} className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                    <Actions.Item
                      show
                      action={(e: any) => handleOpenRemoveModal(e)}
                      icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                  </Actions>
                </div>
              ) : (
                <div className="action-in-large-screen flex justify-end items-center grow ">
                  <Actions>
                    <Actions.Item
                      show
                      action={(e: any) => handleEditExercise(e)}
                      icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                    <Actions.Item
                      show
                      action={(e: any) => handleOpenRemoveModal(e)}
                      icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                  </Actions>
                </div>
              )
            )}
          </div>
          {((expand && !isExpandAll) || (expandAll && isExpandAll)) && (
            <div className='pt-2' onClick={(e) => e.stopPropagation()}>
              {fileUrl !== '' && fileUrl !== null && (
                isVideo ? (

                  <div className='w-full relative aspect-video'>
                    {!isSafari() && !hasVideoUrl && !isPlaying &&
                      <span className="absolute left-0 right-0 top-0 bottom-10 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                        <Svg name='play-icon' className="w-[60px] h-[60px]" />
                      </span>}
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      controls
                      url={fileUrl}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      playIcon={hasVideoUrl ? <button className='hidden'>{''}</button> : <Svg className="w-[60px] h-[60px]" name='play-icon' />}
                    />
                    <div className="flex items-center justify-center">
                      <a href={exercise?.videoUrl} rel="noreferrer" className="pt-4 text-tertiary text-center underline" target="_blank">{exercise?.videoUrl}</a>
                    </div>
                  </div>

                ) : (
                <div className='w-full bg-black aspect-video flex justify-center'>
                    <img className={`preview object-cover  h-auto ${orientation === 'Horizontal' ? 'w-full': 'w-auto'}`} src={fileUrl} alt="avatar"  onLoad={(e) => checkOrientation(e.target)}/>
                  </div>
                ))}
              <div>
                {exercise.description && (
                  <div className="pt-4 whitespace-pre-line">
                    {exercise.description}
                  </div>
                )}

              </div>
              {!isPatientSite && (
                !isTemplate ? (<div className="action-in-small-screen flex justify-start pt-2 items-center grow ">
                  <Actions>
                    <Actions.Item
                      show
                      action={(e: any) => handleEditExercise(e)}
                      icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                    <Actions.Item
                      show
                      action={(event: any) => handleArchiveExercise(event, exercise, exerciseId, index)}
                      icon={exercise.isArchived ? <Svg name="unarchive-icon" width={20} height={20} /> : <HiOutlineArchive width={20} height={20} className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />

                    <Actions.Item
                      show
                      action={(e: any) => handleOpenRemoveModal(e)}
                      icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                  </Actions>
                </div>) : (<div className="action-in-small-screen flex justify-start pt-2 items-center grow ">
                  <Actions>
                    <Actions.Item
                      show
                      action={(e: any) => handleEditExercise(e)}
                      icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                    <Actions.Item
                      show
                      action={(e: any) => handleOpenRemoveModal(e)}
                      icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                    />
                  </Actions>
                </div>)
              )}
            </div>
          )}
        </div>
      </div>
      {openConfirmDeleteModal && <ConfirmDeleteModal openModal={openConfirmDeleteModal} setOpenModal={setOpenConfirmDeleteModal} handleRemove={() => handleRemoveExercise(isArchived ? exercise.id : getValues(`exercises[${index}].id`))} />}
    </>
  )
}

export default ExerciseRow